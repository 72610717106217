module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://api.nve.my.id","contentApiKey":"7c086718efb51ca1c51cb354bd"},"production":{"apiUrl":"https://api.nve.my.id","contentApiKey":"7c086718efb51ca1c51cb354bd"}},"siteConfig":{"siteUrl":"https://arm.my.id","postsPerPage":3,"siteTitleMeta":"Jamstack Blog","siteDescriptionMeta":"Sharing, belajar dan menemukan ide","shareImageWidth":1000,"shareImageHeight":523,"shortTitle":"Jamify","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A"},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    }]
